// import Image from "../../Assets/Images/Logo/Logo.png";
import Axios from "../../../Database/Axios";
import Spin from "../../../Assets/SVG/Spin";

import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ForgotPassword() {
  const history = useNavigate();
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);

  const loginFunction = async () => {
    const data = {
      username: username,
    };
    try {
      setLoading(true);
      const response = await Axios.post("/forgot-password-request/", data);

      if (response.data.status) {
        console.log(response);
        const location = "/forgot-password/otp/" + username;
        history(location);
      } else {
        console.log(response);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex items-center justify-center bg-[#e4e3e3] h-dvh p-3">
      <div className="p-5 max-w-[400px] w-[400px] min-w-[200px] bg-white shadow-md rounded-md">
        <div className="flex justify-center ">
          {/* <img src={Image} alt="not found" className="w-[200px]" /> */}
        </div>
        <h1 className="text-center my-2 text-4xl font-bold text-orange-500">
          Forgot Password?
        </h1>
        <p className="text-center">
          After the submit forgot password request you will recive the mail from
          our website.
        </p>
        <p>Email Address</p>
        <input
          type="text"
          className="w-full h-10 outline-orange-500 border-gray-300 rounded-sm px-2 border-[2px]"
          placeholder="Enter Email Address"
          onChange={(e) => setUsername(e.target.value)}
          value={username}
        />
        <div className="h-2 mt-2">
          <p
            className="float-right cursor-pointer hover:text-gray-600"
            onClick={() => history(-1)}
          >
            Back
          </p>
        </div>
        {!loading && (
          <button
            className="h-9 bg-orange-500 w-full mt-2 text-white rounded-sm outline-none focus-within:bg-orange-600 hover:bg-orange-600 mb-2"
            onClick={loginFunction}
          >
            Submit
          </button>
        )}
        {loading && (
          <button className="h-9 bg-orange-500 w-full mt-2 text-white rounded-sm outline-none focus-within:bg-orange-600 hover:bg-orange-600 mb-2 flex items-center justify-center">
            <Spin />
          </button>
        )}
      </div>
    </div>
  );
}
