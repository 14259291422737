import { Tooltip, IconButton } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";

export default function SeeBooking({ data = [], title = "Title", id }) {
  const navigate = useNavigate();

  return (
    <Tooltip arrow placement="top" title="Group">
      <div className="bg-orange-500 cursor-pointer w-10 h-10 flex items-center justify-center rounded-md shadow-md">
        <IconButton
          className="w-10 h-10 rounded-none"
          onClick={() => navigate(`/employee/group?id=${id}`)}
        >
          <ArrowForwardIosIcon className="text-white" />
        </IconButton>
      </div>
    </Tooltip>
  );
}
