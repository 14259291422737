import { useState } from "react";
import Copy from "../../../Assets/SVG/Copy";
import Check from "../../../Assets/SVG/Check";
import { Tooltip } from "@mui/material";

export default function FormLinkCopyButton({ defaultValue = "hello" }) {
  const [active, setActive] = useState(false);

  const callFunction = () => {
    console.log('hello')
    if (defaultValue) {
      navigator.clipboard
        .writeText(defaultValue)
        .then(() => {
          setActive(true);
          setTimeout(() => {
            setActive(false);
          }, 2000);
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    }
  };

  return (
    <Tooltip arrow placement="top" title="Link">
      <div className="bg-orange-500 cursor-pointer w-10 h-10 flex items-center justify-center rounded-md shadow-md" onClick={callFunction}>
        {!active && <Copy className="text-white hover:text-white" />}
        {active && <Check className="text-white hover:text-white" />}
      </div>
    </Tooltip>
  );
}
