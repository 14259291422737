import { Routes, Route } from "react-router-dom";


// Main Pages
import Login from "./Pages/Other/Login/Login";
import OTP from "./Components/OTP/OTP";
import NotFound from "./Pages/Other/NotFound/NotFound";
import ForgotPassword from "./Pages/Other/Forgot-Password/Forgot-Password";
import ForgotPasswordMain from "./Pages/Other/Forgot-Password/Forgot-Password-Main";
import ForgotPasswordOtp from "./Pages/Other/Forgot-Password/Forgot-Password-Otp";

// User page
import EmployeeDashboard from './Pages/Employee/Home/EmployeeDashboard'
import Account from "./Pages/Employee/Account/Account";
// import Booking from "./Pages/Employee/Booking/Booking";
import Trip from "./Pages/Employee/Trip/Trip";
import Group from "./Pages/Employee/Group/Group";


// Customer Pages
import CustomerForm from "./Pages/Customer/Form";

export default function App() {
  return (
    <Routes>
      {/* Main Pages */}
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/otp/:email" element={<OTP />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/forgot-password-main" element={<ForgotPasswordMain />} />
      <Route path="/forgot-password/otp/:email" element={<ForgotPasswordOtp />} />

      {/* <Route path="/login" element={<Login />} /> */}

    

      {/* Employee Pages */}
      <Route path="/employee/dashboard" element={<EmployeeDashboard />} />
      <Route path="/employee/account" element={<Account />} />
      <Route path="/employee/group" element={<Group />} />
      <Route path="/employee/trip/" element={<Trip />} />

      
      {/* <Route path="/ExampleComponents" element={<ExampleGrid />} /> */}

      

      {/* Customer Pages */}
      <Route path="/customer/form/:trip/:token" element={<CustomerForm />} />

      
      {/* Others */}
      <Route path="*" element={<NotFound />} />

    </Routes>
  );
}
