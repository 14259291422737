import { useState, useEffect } from "react";
import { addNotification } from "../../../Components/Notification/Notification";
import UserSidebarNavbar from "../../../Components/Sidebar/UserSidebarNavbar";
import Token from "../../../Database/Token";
import Loading from "../../../Assets/SVG/Loading";
import Input from "../../../Components/Input/Input";

const Account = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const personalInformationFormat = [
    { id: 1, title: "Name", target: "name", type: "text" },
    { id: 2, title: "Email", target: "email", type: "text" },
    { id: 3, title: "Phone Number", target: "phone", type: "text" },
  ];

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setData(user);
    }
  }, []);

  const callFunction = async () => {
    setLoading(true);
    const jsonData = {
      name: data.name,
      email: data.email,
      phone: data.phone,
    };
    console.log(jsonData)
    try {
      const response = await Token.patch("/user/update/", jsonData);
      if (response.data.status) {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        addNotification("Updated", "Your profile has been updated", "success");
        setData(response.data.user); 
      }
    } catch (e) {
      addNotification("error", e.message, "danger");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e, target) => {
    setData({
      ...data,
      [target]: e.target.value,
    });
  };

  return (
    <UserSidebarNavbar>
      <div className="h-[calc(100%-20px)] w-full p-4">
        <div className="w-full bg-white shadow-md rounded-md p-6 pt-10">
          <h1 className="text-3xl font-bold">
            Welcome <p className="text-orange-500">{data?.name}</p>
          </h1>
          <p className="font-bold mt-3 mb-1 ">Personal Information</p>
          <div className="w-full h-[0.5px] opacity-40 bg-gray-600 shadow-md"></div>

          <div className="grid grid-cols-2 sm:grid-cols-1 max-w-full min-w-0 gap-4 mt-3" key={data}>
            {personalInformationFormat.map((item) => (
              <div key={item.id}>
                <p className="font-bold">{item.title}</p>
                <Input
                  type={item.type}
                  className="w-full h-10 bg-[#f4f6f8] border-[1px] border-gray-300 rounded-sm outline-none focus:border-orange-500 px-2 focus:border-[1.75px]"
                  defaultValue={data ? data[item.target] : ""}
                  onChange={(e) => handleInputChange(e, item.target)}
                />
              </div>
            ))}
          </div>
          {!loading && (
            <button
              className="py-2 mt-4 rounded-md text-white text-xl font-bold px-10 bg-orange-500 shadow-md hover:bg-orange-600 focus-visible:ring-2 focus-visible:ring-offset-2 ring-orange-500 outline-none focus-visible:bg-orange-500"
              onClick={callFunction}
            >
              Save
            </button>
          )}
          {loading && (
            <button className="py-2 mt-4 rounded-md text-white text-xl font-bold px-12 bg-orange-500 shadow-md hover:bg-orange-600 focus-visible:ring-2 focus-visible:ring-offset-2 ring-orange-500 outline-none focus-visible:bg-orange-500">
              <Loading />
            </button>
          )}
        </div>
      </div>
    </UserSidebarNavbar>
  );
};

export default Account;
