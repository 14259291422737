import UpArrow from "../../Assets/SVG/UpArrow";
import SelectOption from "./SelectOption";
import { useState } from "react";

import { useAppContext } from "../../Database/Context";
import Export from "./Export";

export default function Table({
  cPage = 1,
  lPage = 1,
  data = [],
  headers = [],
  updatePage = () => {},
  updateRow = () => {},
  button = "",
  callFunction = () => {},
  children = [],
  className= ""
}) {
  const [active, setActive] = useState(cPage);
  const { state } = useAppContext();

  //   Dots to traverse in reverse

  const negDots = (e) => {
    console.log(`You clicked ${e} so you r on page`, e);
    var temp = active - e;
    if (temp <= 0) {
      setActive(1);
      updatePage(1);
      console.log(active);
    } else {
      setActive(temp);
      updatePage(temp);
      console.log(active);
    }
  };

  //   Dots to traverse in forward
  const posDots = (e) => {
    console.log(`You clicked ${e} so you r on page`, e);
    var temp = active + e;
    if (temp >= lPage) {
      setActive(lPage);
      updatePage(lPage);
      console.log(active);
    } else {
      setActive(temp);
      updatePage(temp);
      console.log(active);
    }
  };

  const changeActive = (e) => {
    console.log("This is e : ", e);
    console.log("This is last page", lPage);
    const page = active + e;
    if (page <= 0) {
      console.log(
        "Current page is updated : You have clicked -1 button :",
        active
      );
      setActive(1);
      updatePage(active);
      console.log(active);
    } else if (page >= lPage + 1) {
      setActive(lPage);
      updatePage(active);
    } else {
      setActive(page);
      updatePage(page);
      console.log(page);
    }
  };

  const end = (value) => {
    console.log(`You have clicked ${value}. So, u r on page 1`);
    setActive(value);
    updatePage(value);
  };

  const track = (value) => {
    console.log("Value is", value);
    setActive(value);
    updatePage(value);
  };

  const buttonOne = () => {
    return active === 1
      ? 2
      : active === 2
      ? 2
      : active === lPage
      ? active - 3
      : active === lPage - 1
      ? active - 2
      : active - 1;
  };

  const buttonTwo = () => {
    return active === 1
      ? 3
      : active === 2
      ? 3
      : active === lPage
      ? active - 2
      : active === lPage - 1
      ? active - 1
      : active;
  };

  const buttonThree = () => {
    return active === 1
      ? 4
      : active === 2
      ? 4
      : active === lPage
      ? lPage - 1
      : active === lPage - 1
      ? lPage - 1
      : active + 1;
  };

  return (
    <div className="relative overflow-x-auto shadow-md bg-white rounded-md min-h-72">
      <div className="h-16">
        <div className="float-left">{button} </div>

        <SelectOption update={(e) => updateRow(e)} />
        <Export onClick={() => callFunction()} data={data} />
        <svg
          aria-hidden="true"
          className={`w-7 h-7 my-[17px] float-right  text-gray-200 ${
            state.isLoading && "animate-spin"
          } dark:text-orange-200 fill-orange-500`}
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
      </div>
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        {children}
      </table>
      <div className="h-14">
        <div className="grid-cols-9 gap-x-2 grid float-right p-3">
          {/*  this is for change -1 */}

          <button
            className="flex items-center justify-center -rotate-90  w-9 h-9 max-h-9 bg-white group border-[1px] border-orange-500 rounded-md hover:bg-orange-500 hover:text-white outline-none ring-offset-2 ring-orange-500 focus-visible:ring-2 fill-orange-500 text-orange-500"
            onClick={() => changeActive(-1)}
          >
            <UpArrow />
          </button>

          {/* this is for 11111111111 ---- first----- button */}

          {lPage > 1 && (
            <button
              className={` w-9 h-9 max-h-9 ${
                active === 1
                  ? "bg-orange-500 text-white"
                  : "bg-white text-orange-500"
              } border-[1px] border-orange-500 rounded-md hover:bg-orange-500 hover:text-white outline-none ring-offset-2 ring-orange-500 focus-visible:ring-2 ${
                className
              }`}
              onClick={() => end(1)}
            >
              1
            </button>
          )}

          {/* this is for ... three dot button  */}

          {lPage > 2 && (
            <button
              className={`flex items-center justify-center rotate-90  w-9 h-9 max-h-9 bg-white group border-[1px] border-orange-500 rounded-md hover:bg-orange-500 hover:text-white outline-none ring-offset-2 ring-orange-500 focus-visible:ring-2`}
              onClick={() => negDots(3)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                className="bi bi-three-dots rotate-90 fill-orange-500 group-hover:fill-white"
                viewBox="0 0 16 16"
              >
                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
              </svg>
            </button>
          )}

          {/* this is for 222222222222 */}

          {lPage > 2 && (
            <button
              className={` w-9 h-9 max-h-9 ${
                active === 2
                  ? "bg-orange-500 text-white"
                  : "bg-white text-orange-500"
              } border-[1px] border-orange-500 rounded-md hover:bg-orange-500 hover:text-white outline-none ring-offset-2 ring-orange-500 focus-visible:ring-2 ${
                className
              }`}
              onClick={() => track(buttonOne)}
            >
              {buttonOne()}
            </button>
          )}
          {/* this is for 3333333333 */}

          {lPage > 3 && (
            <button
              className={` w-9 h-9 max-h-9 ${
                2 < active && active < lPage - 1
                  ? "bg-orange-500 text-white"
                  : "bg-white text-orange-500"
              } border-[1px] border-orange-500 rounded-md hover:bg-orange-500 hover:text-white outline-none ring-offset-2 ring-orange-500 focus-visible:ring-2 ${
                className
              }`}
              onClick={() => track(buttonTwo)}
            >
              {buttonTwo()}
            </button>
          )}

          {/* this is for 44444444444444 */}

          {lPage > 4 && (
            <button
              className={` w-9 h-9  ${
                lPage - 1 === active
                  ? "bg-orange-500 text-white"
                  : "bg-white text-orange-500"
              } border-[1px] border-orange-500 rounded-md hover:bg-orange-500 hover:text-white outline-none ring-offset-2 ring-orange-500 focus-visible:ring-2 ${
                className
              }`}
              onClick={() => track(buttonThree)}
            >
              {buttonThree()}
            </button>
          )}

          {/*  this is for three dot  ... */}

          {lPage > 2 && (
            <button
              className={`flex items-center justify-center rotate-90  w-9 h-9 max-h-9 bg-white group border-[1px] border-orange-500 rounded-md hover:bg-orange-500 hover:text-white outline-none ring-offset-2 ring-orange-500 focus-visible:ring-2`}
              onClick={() => posDots(3)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                className="bi bi-three-dots rotate-90 fill-orange-500 group-hover:fill-white"
                viewBox="0 0 16 16"
              >
                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
              </svg>
            </button>
          )}

          {/* this is for last page setting */}

          <button
            className={` w-9 h-9 max-h-9 ${
              active === lPage
                ? "bg-orange-500 text-white"
                : "bg-white text-orange-500"
            } border-[1px] border-orange-500 rounded-md hover:bg-orange-500 hover:text-white outline-none ring-offset-2 ring-orange-500 focus-visible:ring-2 ${
              className
            }`}
            onClick={() => end(lPage)}
          >
            {lPage}
          </button>

          {/* this is for ++++++++++11111111 page */}

          <button
            className={`flex items-center justify-center rotate-90  w-9 h-9 max-h-9 bg-white group border-[1px] border-orange-500 rounded-md hover:bg-orange-500 hover:text-white outline-none ring-offset-2 ring-orange-500 focus-visible:ring-2 fill-orange-500 text-orange-500`}
            onClick={() => changeActive(1)}
          >
            <UpArrow />
          </button>
        </div>
      </div>
    </div>
  );
}
