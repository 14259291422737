import { useState, Fragment } from "react";
import Token from "../../Database/Token";
import { addNotification } from "../../Components/Notification/Notification";


export default function StatusPopUp({
  onUpdate = () => {},
  api,
  title = "pending",
  format = [
    { title: "Pending", target: "pending", color: "yellow" },
    { title: "Confirmed", target: "confirmed", color: "green" },
    { title: "Canceled", target: "canceled", color: "red" },
  ],
}) {
  const [active, setActive] = useState(false);
  const statusChange = async (title) => {
    setActive(false);
    const data = {
      status: title
    }
    console.log(data);
    try {
      const response = await Token.patch(api,data);
      if (response.data.status) {
        onUpdate();
        addNotification(
          "Successful booking",
          "Your booking has been Updated",
          "success"
        );
      } else {
        addNotification("Warning", response.data.message, "warning");
        console.log("Error message from server:", response.data.message);
      }
    } catch (error) {
      addNotification("Failed", error.message, "danger");
      console.error("Error during API call:", error);
    }
  };

  return (
    <Fragment>
      <div
        onClick={() => setActive(true)}
        className={`${
          title === "pending"
            ? "bg-yellow-300 border-yellow-400 text-yellow-600"
            : title === "confirmed"
            ? "bg-green-500 border-green-600 text-green-100"
            : title === "completed"
            ? "bg-blue-500 border-blue-600 text-blue-100"
            : "bg-red-500 border-red-600 text-red-100"
        } border-2 w-min rounded-md px-2 py-1 cursor-pointer`}
      >
        {title}
      </div>

      <div
        className={`z-[5] w-screen h-screen fixed inset-0 flex items-center justify-center p-3 ${
          active ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`max-w-[450px] w-[450px] bg-white rounded-md shadow-xl p-5 transition-all ${
            active ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <p className="my-3 text-lg">Do you want to change Status</p>
          <br />

          <div className="grid grid-cols-1 gap-2">
            {format.map((list, index) => (
              <button
                key={index}
                className={`w-full h-12 ${list.color === 'yellow'?"bg-yellow-400 hover:bg-yellow-500 ring-yellow-500":`bg-${list.color}-500 hover:bg-${list.color}-600 ring-${list.color}-500`} ring-offset-2 outline-none focus-visible:ring-2 text-white text-xl rounded-md shadow-md`}
                onClick={() => statusChange(list.target)}
              >
                {list.title}
              </button>
            ))}
            <button
              className="w-full h-12 text-red-500 border-[1.5px] border-gray-500 border-dotted rounded-md shadow-md hover:bg-red-50 "
              onClick={() => setActive(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
 