// import Image from "../../Assets/Images/Logo/Logo.png";
import Axios from "../../../Database/Axios";

import Spin from "../../../Assets/SVG/Spin";
import Eye from "../../../Assets/SVG/Eye";
import EyeSlash from "../../../Assets/SVG/EyeSlash";

import { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { addNotification } from "../../../Components/Notification/Notification";
export default function ForgotPasswordMain() {
  const location = useLocation();
  const history = useNavigate();
  const params = useParams();

  const [password, setPassword] = useState("");
  const [conformPassword, setConformPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [eye1, setEye1] = useState(true);
  const [eye2, setEye2] = useState(true);

  const loginFunction = async () => {
    setLoading(true);
    const data = {
      token: location.state?.token,
      password: password,
      username: params.username,
    };
    try {
      console.log(data);
      const response = await Axios.post("/change-password/", data);
      console.log(response.data);
      if (response.data.status) {
        addNotification(`Send`, "Your password has been change", "success");
        history("/");
      } else {
        addNotification(`Wrong`, response.data.message, "danger");
      }
    } catch (e) {
      addNotification(`Error`, e.message, "success");
    }
    setLoading(false);
  };
  return (
    <div className="flex items-center justify-center bg-[#e4e3e3] h-dvh p-3">
      <div className="p-5 max-w-[400px] w-[400px] min-w-[200px] bg-white shadow-md rounded-md">
        <h1 className="text-center my-2 text-4xl font-bold text-orange-500">
          Enter your Password!
        </h1>
        <p className="text-center">
          after you enter the password and conform password you'l edirect to
          login page,
        </p>
        <div className="h-1"></div>
        <label>Enter Password</label>
        <div className="group relative">
          <input
            type={eye1 ? "password" : "text"}
            className="h-10 w-full rounded-s-sm border-gray-300 border-[2px] outline-none px-2 focus-within:border-orange-500 group-focus:border-orange-500 float-left"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            placeholder="Enter password"
          />
          <div
            className="absolute right-[0%] w-10 h-10 hover:border-[1.5px] border-l-0 flex items-center justify-center hover:border-orange-500 hover:border-l-[2px] group-focus:border-orange-500 rounded-e-sm"
            onClick={() => setEye1(!eye1)}
          >
            {!eye1 && <Eye />}
            {eye1 && <EyeSlash />}
          </div>
        </div>
        <div className="h-2"></div>
        <label>Conform Password</label>
        <div className="group relative">
          <input
            type={eye2 ? "password" : "text"}
            className="h-10 w-full rounded-s-sm border-gray-300 border-[2px] outline-none px-2 focus-within:border-orange-500 group-focus:border-orange-500 float-left"
            onChange={(e) => setConformPassword(e.target.value)}
            value={conformPassword}
            placeholder="Enter password"
          />
          <div
            className="absolute right-[0%] w-10 h-10 hover:border-[1.5px] border-l-0 flex items-center justify-center hover:border-orange-500 hover:border-l-[2px] group-focus:border-orange-500 rounded-e-sm"
            onClick={() => setEye2(!eye2)}
          >
            {!eye2 && <Eye />}
            {eye2 && <EyeSlash />}
          </div>
        </div>

        <div className="h-2 mt-2">
          <p
            className="float-right cursor-pointer hover:text-gray-600"
            onClick={() => history(-1)}
          >
            Back
          </p>
        </div>

        <br />
        {!loading && (
          <button
            className="h-9 bg-orange-500 w-full mt-2 text-white rounded-sm outline-none focus-within:bg-orange-600 hover:bg-orange-600 mb-2"
            onClick={loginFunction}
          >
            Submit
          </button>
        )}
        {loading && (
          <button className="h-9 bg-orange-500 w-full mt-2 text-white rounded-sm outline-none focus-within:bg-orange-600 hover:bg-orange-600 mb-2 flex items-center justify-center">
            <Spin />
          </button>
        )}
      </div>
    </div>
  );
}
